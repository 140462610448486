import React from "react"
// import * as styles from "./noi-thuy-tinh-cao-cap-page.module.scss"
import { MasterLayoutScene } from "@bep43vn/scenes"
import { MainContent } from "@bep43vn/windgets/main-content"
import { LocationRoute } from "@bep43vn/pages/page-routing"
import { graphql, useStaticQuery } from "gatsby"

export const DieuHoaMoiPage: React.FCWithRoute<DieuHoaPropsType> = () => {
  const pageData = useDieuHoaMoiData()
  return (
    <MasterLayoutScene
      collectionName="Điều hòa"
      collectionHref={LocationRoute.DieuHoa}
    >
      <MainContent {...pageData}></MainContent>
    </MasterLayoutScene>
  )
}

export const useDieuHoaMoiData = (): DieuHoaPropsType => {
  const data = useStaticQuery(graphql`
    query useDieuHoaMoiData($type: String = "dieu-hoa") {
      allMarkdownRemark(
        sort: { fields: [frontmatter___date], order: DESC }
        filter: { fields: { type: { eq: $type } } }
      ) {
        totalCount
        edges {
          node {
            id
            frontmatter {
              title
              price
              comparePrice
              imageThumbnail
              description
              productSale
              collectionName
              date(formatString: "DD MMMM, YYYY")
            }
            fields {
              slug
            }
            excerpt
          }
        }
      }
    }
  `)
  return data
}

export type DieuHoaPropsType = {
  allMarkdownRemark: {
    totalCount: number
    edges: Array<{
      node: {
        id: string
        frontmatter: {
          price: string
          title: string
          comparePrice: string
          productSale: string
          date: string
          description: string
          imageThumbnail: string
        }
        fields: {
          slug: string
        }
        excerpt: string
      }
    }>
  }
}

export default DieuHoaMoiPage
